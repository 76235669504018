import axios from 'axios'
import Router from '@/router'
import {
  Message
} from 'element-ui'
// 创建axios实例
const service = axios.create({
  timeout: 15000, // 请求超时时间
  headers:{
    "Content-Type":'application/x-www-form-urlencoded;charset=UTF-8',
    "Accept-Language":"zh-CN,zh;q=0.9"
  },
  withCredentials:true
})
// request拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    Message.error("服务器异常");
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    if (response.status !== 200) {
        Message.error("服务器异常");
        return Promise.reject(response.data);
    } else {
      if(response.data.code === 1){
        response.data.Code = 0;
        return response;
      } else if(response.data.code == -200){
        Message.error('登录失效，请刷新重新登录');
        Router.push('/Login');
        return response;
      } else{
        return response;
      }
    }
  },
  err => {
    var response = err.response;
    Message.error("服务器异常");
    return Promise.reject(response);
  }
)
function cleanArray(actual) {
    const newArray = [];
    for (let i = 0; i < actual.length; i++) {
      if (actual[i]) {
        newArray.push(actual[i]);
      }
    }
    return newArray;
}
function toQueryString(obj) {
    if (!obj) return "";
    return cleanArray(
      Object.keys(obj).map(key => {
        if (obj[key] === undefined) return "";
        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
      })
    ).join("&");
}
//post
export function post(url,params = {}){
  return new Promise((resolve, reject)=>{
      service.post(url,toQueryString(params)).then(response=>{
        resolve(response.data);
      }).catch(err=>{
        reject(err);
      })
    })
}
export function get(url,params = {}){
  return new Promise((resolve, reject)=>{
      service.get(url,toQueryString(params)).then(response=>{
        resolve(response.data);
      }).catch(err=>{
        reject(err);
      })
    })
}

 
